<footer>
  <div class="footerContact">
    <div class="container-md">
      <div class="row justify-content-center justify-content-md-between">
        <div class="col-sm-auto col-md-4 col-xl-auto contactShot">
          <div class="row align-items-center">
            <div class="col-auto pr-0">
              <img src="assets/images/footerIco1.svg" alt="" class="">
            </div>
            <div class="col">
              <a href="" class="text-white">540 Cambridge St. <br>
                Manassas, VA 20109</a>
            </div>
          </div>
        </div>
        <div class="col-sm-auto col-md-4 col-xl-auto contactShot">
          <div class="row align-items-center">
            <div class="col-auto pr-0">
              <img src="assets/images/footerIco2.svg" alt="" class="">
            </div>
            <div class="col">
              <a href="mailto:chloemickeyy@gmail.com" class="text-white">chloemickeyy@gmail.com</a>
            </div>
          </div>
        </div>
        <div class="col-sm-auto col-md-4 col-xl-auto  ">
          <div class="row align-items-center">
            <div class="col-auto pr-0">
              <img src="assets/images/footerIco3.svg" alt="" class="">
            </div>
            <div class="col">
              <a href="tel:+19788686490" class="text-white">+19788686490</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="socialFooter">
    <ul class="list-unstyled m-0 d-flex justify-content-center">
      <li><a href=""><img src="assets/images/social1.svg" alt="" class=""></a></li>
      <li><a href=""><img src="assets/images/social2.svg" alt="" class=""></a></li>
      <li><a href=""><img src="assets/images/social3.svg" alt="" class=""></a></li>
    </ul>
  </div>

  <div class="footerNavWrap">
    <ul class="list-unstyled m-0 d-flex flex-wrap justify-content-center footerNav">
      <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
      <li><a routerLink="/about" routerLinkActive="active">About Us</a></li>
      <li><a routerLink="/how-it-works" routerLinkActive="active">How It Works</a></li>
      <li><a routerLink="/why-choose-us" routerLinkActive="active">Why Choose Us</a></li>
      <li><a routerLink="/membership-plan" routerLinkActive="active">Pricing & Plan</a></li>
      <li><a routerLink="/contact" routerLinkActive="active">Contact Us</a></li>
    </ul>

    <ul class="list-unstyled m-0 d-flex flex-wrap justify-content-center footerCopy">
      <li><span>Copyright © 2021 <a routerLink="/home">PACK2ME</a>.</span></li>
      <li><span>All Rights Reserved.</span></li>
    </ul>
  </div>
</footer>
