import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  @Input() appDropdown: string;
  isInsideClick: boolean;
  isOutsideClick: boolean;

  constructor(
    private element: ElementRef,
  ) {


    this.element.nativeElement.onclick = () => {
      const block = document.getElementById(this.appDropdown);
      if (block) {
        block.classList.toggle('opend');
      }
    };
  }

  @HostListener('click', ['$event'])
  clickInside(event: any): void {
    this.isInsideClick = true;
    this.isOutsideClick = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    if (!this.isInsideClick && this.isOutsideClick) {
      this.isOutsideClick = false;
    }
    this.isInsideClick = false;
    if (!this.isInsideClick && !this.isOutsideClick) {
      const block = document.getElementById(this.appDropdown);
      if (block) {
        block.classList.remove('opend');
      }
    }
  }


}
