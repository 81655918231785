import { Component, OnInit } from '@angular/core';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  USER: any = '';

  constructor(
    private storage: StorageService,
    private event: EventService,
    ) { }

  ngOnInit(): void {
    this.event.isLogin.subscribe((res: any) => {
      console.log('login', res);
      if (res) {
        this.USER = this.storage.getUser();

      }
    });
  }

  openMobileMenu() {
    let cmsMenus = document.querySelector('.cmsMenus');
    cmsMenus?.classList.toggle('open');
    document.body.classList.toggle('modal-open')
  }

}
