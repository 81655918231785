<header class="">
  <div class="container-md">
    <div class="row align-items-center">
      <div class="col-sm-auto logo text-center pb-3 pb-sm-0">
        <a [routerLink]="['/']" routerLinkActive="router-link-active" class=""><img src="assets/images/logo.svg"
            alt="Logo" class="img-fluid" /></a>
      </div>

      <div class="col-sm-auto ml-auto text-center">
        <div class="text-right w-100 logRegBtn d-flex d-sm-block">
          <ng-container *ngIf="!USER">
            <a class="btn btn-primary rounded-0 mr-2" routerLink="/sign-in">LOGIN</a>
            <a class="btn btn-outline-primary rounded-0" routerLink="/membership-plan">REGISTER</a>
          </ng-container>
          <ng-container *ngIf="USER">
            <a class="btn btn-primary rounded-0 mr-2" routerLink="/dashboard">DASHBOARD</a>
          </ng-container>
          <a class="
              btn btn-info
              px-2
              ml-auto ml-sm-2
              rounded-0
              py-2
              d-inline-block d-lg-none
            " (click)="openMobileMenu()">
            <span class="material-icons d-block text-white"> menu </span>
          </a>
        </div>
        <div class="cmsMenus" (click)="openMobileMenu()">
          <div class="
              d-flex
              firstArea
              justify-content-between
              align-items-center
              d-lg-none
            ">
            <a [routerLink]="['/']" routerLinkActive="router-link-active" class=""><img src="assets/images/logo.svg"
                alt="Logo" class="img-fluid" /></a>
            <a class="btn btn-danger px-1 ml-2 rounded-0 py-1">
              <span class="material-icons d-block text-white"> close </span>
            </a>
          </div>
          <nav class="">
            <ul class="list-unstyled d-lg-flex m-0">
              <li class="">
                <a routerLink="/home" routerLinkActive="active" class="">Home</a>
              </li>
              <li class="">
                <a routerLink="/about" routerLinkActive="active" class="">About Us</a>
              </li>
              <li class="">
                <a routerLink="/how-it-works" routerLinkActive="active" class="">How It Works</a>
              </li>
              <li class="">
                <a routerLink="/why-choose-us" routerLinkActive="active" class="">Why Choose Us</a>
              </li>
              <li class="">
                <a class="">Pricing & Plan</a>
                <div class="childMenu">
                  <ul class="list-unstyled">
                    <li>
                      <a routerLink="/service-pricing" routerLinkActive="active" class="">Service PRICING</a>
                    </li>
                    <li>
                      <a routerLink="/membership-plan" routerLinkActive="active" class="">MEMBERSHIP Plan</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="">
                <a routerLink="/contact" routerLinkActive="active" class="">Contact Us</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>