import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  TEMP = {
    KEY: '_project_auth_1',
    PASSWORD: '90590348534YYIU!@00',
  };
  USER = {
    KEY: '_project_auth_0',
    PASSWORD: '!##0895*()?:}95047834&&tes',
  };
  TOKEN = {
    KEY: '_main_project_token_0',
    PASSWORD: '!##0895*()?:}95047834&&teg',
  };
  REMEMBERTOKEN = {
    KEY: '_project_remember_token_0',
    PASSWORD: '!##0895*()?:}95047834&&teh',
  };

  constructor(private cookie: CookieService) {}

  private encription(data: any, secret: string): any {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret);
  }
  private decription(data: any, secret: string): any {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  setTempData(DATA: any): any {
    return this.cookie.set(
      this.TEMP.KEY,
      this.encription(DATA, this.TEMP.PASSWORD).toString()
    );
  }

  getTempData(): any {
    const DATA =
      this.cookie.get(this.TEMP.KEY) !== null
        ? this.cookie.get(this.TEMP.KEY)
        : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearTempData(): any {
    return this.cookie.delete(this.TEMP.KEY);
  }

  async setUser(data: any): Promise<any> {
    return this.cookie.set(
      this.USER.KEY,
      this.encription(data, this.USER.PASSWORD).toString()
    );
  }

  getUser(): any {
    const DATA =
      this.cookie.get(this.USER.KEY) !== null
        ? this.cookie.get(this.USER.KEY)
        : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser(): any {
    return this.cookie.delete(this.USER.KEY);
  }
  async setToken(data: any): Promise<any> {
    return this.cookie.set(
      this.TOKEN.KEY,
      this.encription(data, this.TOKEN.PASSWORD).toString()
    );
  }
  async rememberToken(data: any): Promise<any> {
    return this.cookie.set(
      this.REMEMBERTOKEN.KEY,
      this.encription(data, this.REMEMBERTOKEN.PASSWORD).toString()
    );
  }

  getToken(): any {
    const DATA =
      this.cookie.get(this.TOKEN.KEY) !== null
        ? this.cookie.get(this.TOKEN.KEY)
        : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TOKEN.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearToken(): any {
    return this.cookie.delete(this.TOKEN.KEY);
  }

  getDataField(type: string): any {
    if (this.getUser() !== undefined && this.getUser()[type] !== undefined) {
      return this.getUser()[type];
    } else {
      return false;
    }
  }

  isAuthenticate(): boolean {
    if (this.getDataField('token') !== undefined) {
      return true;
    } else {
      return false;
    }
  }
}
